import Link from "next/link";
import { useEffect, useState } from "react";
import client from "../../../../client";

export const Discount = () => {
  const [discount, setDiscount] = useState([]);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(screenSize, "screenSize");

  useEffect(() => {
    client.fetch(`*[_type == "discountBanner"]`).then((data) => {
      // let arr = data[0]
      setDiscount(data[0]);
    });
  }, []);

  console.log("discount", discount);

  return (
    <div
      style={{
        height: 600,
        display: "flex",
        flexDirection: screenSize.width > "767" ? "row" : "column",
      }}
    >
      {/* <!-- BEGIN DISCOUNT --> */}
      <div
        style={{
          backgroundImage: `url('/assets/img/kid.webp')`,
          backgroundSize: "cover",
          width: screenSize.width > "767" ? "50%" : "100%",
          backgroundPosition: screenSize.width > "767" ? "left" : "-15%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",

            height: screenSize.width > "767" ? "600px" : "300px",
          }}
        >
          <div style={{ marginLeft: screenSize.width > "767" && "3rem",padding:'1rem' }}>
            <span className="saint-text">Discount</span>
            <span
              className="main-text"
              style={{ fontSize: 30, paddingLeft: 0 }}
            >
              Get <span>{discount?.Discount}%</span> off
            </span>
            <p className="spread-referral-msg">
              {discount?.bannerContent}
            </p>

            <Link href="/shop">
              <a className="btn">get now!</a>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url('https://img.freepik.com/free-photo/happy-baby-boy-sitting-with-toy_155003-667.jpg?w=2000&t=st=1704961838~exp=1704962438~hmac=ba27c2401e3ea2f5e46233d19d74a68f15811d9093115759a8999dbb9b67bc50')`,
          width: screenSize.width > "767" ? "50%" : "100%",
          backgroundSize: "cover",
          backgroundPosition: screenSize.width > "767" ? "left" : "75%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",

            height: screenSize.width > "767" ? "600px" : "300px",
          }}
        >
          <div style={{ marginLeft: screenSize.width > "767" && "5rem",padding:'1rem' }}>
            <span className="saint-text">Referral</span>
            <span
              className="main-text"
              style={{ fontSize: 30, paddingLeft: 0 }}
            >
              Get <span>20%</span> off
            </span>
            <p
             
              className="spread-referral-msg"
            >
              Spread the word about our awesome services and
              <br /> earn exciting perks with our CUSTOMER REFERRAL program!
            </p>

            <Link href="/profile">
              <a className="btn">get now!</a>
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- DISCOUNT EOF   --> */}
    </div>
  );
};
{
  /* <!-- BEGIN DISCOUNT --> */
}
//<div style={{width: "100vw", height:"100vh"}}>
//  <div
//    className="discount-home-wrapper"
//    style={{
//    backgroundColor: "#fef2d3",
//      height: '100%',
//      width: '100%',
//      display: "flex",
//      alignItems: "center",
//
//    }}
//    >
//
//  </div>
//</div>

{
  /* <!-- DISCOUNT EOF   --> */
}
