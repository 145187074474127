import { CartContext } from 'pages/_app';
import { useContext } from 'react';
import {SingleProduct} from "../Products/SingleProduct/SingleProduct";
import client from "../../../../client";

export const ProductHome = ({ products }) => {
    const { cart, setCart } = useContext(CartContext);

    const handleAddToCart = (id) => {
        const newProduct = products?.find((pd) => pd.id === id);
        const existingProductIndex = cart.findIndex((pd) => pd.id === id);

        if (existingProductIndex !== -1) {
            // If the product already exists in the cart, update its quantity
            const updatedCart = [...cart];
            updatedCart[existingProductIndex].quantity += 1;
            setCart(updatedCart);
        } else {
            // If the product is not in the cart, add it as a new item
            setCart([...cart, { ...newProduct, quantity: 1 }]);
        }
    };



    return (
        <>
            {products?.slice(0, 6).map((product) => {
                console.log('product map', product)

                return (
                    <div className='shop-main__items' >
                    <SingleProduct
                        addedInCart={Boolean(cart?.find((pd) => pd.id === product.id))}
                        key={product._id}
                        product={product}
                        onAddToWish={(id) => console.log(id)}
                        onAddToCart={handleAddToCart}
                    />
                    </div>
                )})}
        </>
    );
};
