import { Advantage } from "components/shared/Advantage/Advantage";
import { Banner } from "components/landing/Banner/Banner";
import { BrandLogo } from "components/shared/BrandLogo/BrandLogo";
import { Discount } from "components/landing/Discount/Discount";
import { Info } from "components/landing/Info/Info";
import { Subscribe } from "components/shared/Subscribe/Subscribe";
import { Layout } from "layout/Layout";
import client from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { Products } from "../components/Product/Products/Products";
import { ProductHome } from "components/Product/ProductHome/ProductHome";
import Head from "next/head";
import { useState, useEffect, useContext, useRef } from "react";
import { useRouter } from "next/router";
import { Loader } from "../components/Loader/Loader";
import { UserContext } from "../context/UserContext";
import { FaVolumeMute } from "react-icons/fa";
import { FaVolumeUp } from "react-icons/fa";

export default function Home() {
  // Get a pre-configured url-builder from your sanity client
  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [bookings, setBooking] = useState([]);
  const [premiumProducts, setPremiumProducts] = useState([]);
  const router = useRouter();
  const { cart, setCart } = useContext(UserContext);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  useEffect(() => {
    const query = `*[_type == "Toy" && !(_id in path('drafts.**')) && isDraft == false] | order(_createdAt desc)`;
    client.fetch(query).then((res) => {
      const discountedProducts = res.map((item) => {
        if (item.hasOwnProperty("discount")) {
          return {
            ...item,
            originalPrice: item.price,
            price: item.price - (item.discount / 100) * item.price,
          };
        } else {
          return { ...item, originalPrice: 0 };
        }
      });
      setProducts([...discountedProducts]);
      setLoading(false);
    });
  }, []);

  const videoRef = useRef(null);
  const videoRefTwo = useRef(null);

  const [videoMuted, setVideoMuted] = useState(true);
  const [videoMutedTwo, setVideoMutedTwo] = useState(true);

  const toggleMute = () => {
    const video = videoRef.current;
    video.muted = !video.muted;
    setVideoMuted(video.muted);
  };

  const toggleMuteTwo = () => {
    const video = videoRefTwo.current;
    video.muted = !video.muted;
    setVideoMutedTwo(video.muted);
  };

  // Function to generate the date range and fetch bookings
  useEffect(() => {
    const today = new Date();
    const lastMonthToday = new Date(
      today.getFullYear(),
      today.getMonth() - 2,
      today.getDate()
    );

    const toDate = today.toISOString();
    const fromDate = lastMonthToday.toISOString();

    // Your existing GROQ query remains unchanged
    const query = `*[_type == "Booking" && from >= "${fromDate}" && to <= "${toDate}"] | order(unitsBooked desc) {
      unitsBooked,
      toy->{
        _id,
        "toyType": type,
      },
    }`;

    client
      .fetch(query)
      .then((bookings) => {
        // Shuffle the bookings array to randomize
        const shuffledBookings = bookings.sort(() => 0.5 - Math.random());

        // Process and fetch toys based on shuffled bookings
        const popularProduct = shuffledBookings
          .slice(0, 10)
          .map((each) => each?.toy?._id);

        console.log("Random popular toys:", popularProduct);

        // Your existing logic to fetch toys by IDs
        const toyQuery = '*[_type == "Toy" && _id in $ids && isDraft == false]';

        const params = { ids: popularProduct };

        client
          .fetch(toyQuery, params)
          .then((toys) => {
            console.log(toys, "Popular toys after shuffle");
            setBooking(toys);
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
      })
      .catch((err) => console.error("Failed to fetch bookings:", err));
  }, []); // Dependency array empty to run once on component mount

  useEffect(() => {
    // For premium products with isDraft set to false
    const query = '*[_type == "Toy" && price > 1200 && isDraft == false]';

    client
      .fetch(query)
      .then((toys) => {
        // Randomly shuffle array (Fisher-Yates shuffle algorithm)
        const shuffled = toys.sort(() => 0.5 - Math.random());
        // Get sub-array of first N elements after shuffled
        let selected = shuffled.slice(0, 5);
        console.log(selected, "premium");
        setPremiumProducts(selected);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null, // observing for viewport
      threshold: 0.5, // trigger callback when 50% of the element is visible
    };

    // Observer callback function
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        // Check if the video is visible
        if (entry.isIntersecting) {
          // Play video if it's visible
          entry.target.play();
        } else {
          // Pause video if it's not visible
          entry.target.pause();
        }
      });
    };

    // Create two observers for each video
    const observer1 = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const observer2 = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Get video elements
    const video1 = videoRef.current;
    const video2 = videoRefTwo.current;

    // Observe the videos
    if (video1) observer1.observe(video1);
    if (video2) observer2.observe(video2);

    // Clean up function to disconnect observers when the component unmounts
    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <>
      <Head>
        {/* Gtag */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-J4L1LPY9BQ"
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-J4L1LPY9BQ');
            `,
          }}
        />

        <title>
          Jusplaytoys – Toy Renting Library / Soft Playarea Setups / Birthday
          Party Planner
        </title>
        <meta property="og:url" content="https://jusplyatoys.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Jusplaytoys – Toy Renting Library / Soft Playarea Setups / Birthday Party Planner"
        />
        <meta
          property="og:image"
          content="https://jusplaytoys.com/assets/img/jusplay/jusplay-4.jpg"
        />
      </Head>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <Banner />
          {/*<BrandLogo />*/}

          <div
            style={{
              background: "transparent",
              display: "flex",
              padding: "1rem",
            }}
            className="video-home-div"
          >
            <div className={"videoContainer"}>
              <video
                ref={videoRefTwo}
                className="video-tag"
                autoPlay
                muted
                loop
              >
                <source
                  src="https://main-object-storage.blr1.cdn.digitaloceanspaces.com/WhatsApp%20Video%202024-03-18%20at%2014.18.28.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <span className={"muteButton"} onClick={toggleMuteTwo}>
                {videoMutedTwo ? (
                  <FaVolumeMute size={20} />
                ) : (
                  <FaVolumeUp size={20} />
                )}
                <span>{!videoMutedTwo ? "ON" : "OFF"}</span>
              </span>
            </div>
          </div>
          <Discount />
          {/*<Advantage />*/}
          <div
            style={{
              background: "transparent",
              display: "flex",
              padding: "1rem",
            }}
            className="video-home-div"
          >
            <div className={"videoContainer"}>
              <video ref={videoRef} className="video-tag" autoPlay muted loop>
                <source
                  src="https://main-object-storage.blr1.cdn.digitaloceanspaces.com/WhatsApp%20Video%202024-03-18%20at%2014.18.38.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <span className={"muteButton"} onClick={toggleMute}>
                {videoMuted ? (
                  <FaVolumeMute size={20} />
                ) : (
                  <FaVolumeUp size={20} />
                )}
                <span>{!videoMuted ? "ON" : "OFF"}</span>
              </span>
            </div>
          </div>

          <div style={{ margin: "60px 20px" }}>
            <div className="toys-adored-customer">Toys Adored By Customer</div>
            <div className="shop-main__items">
              <Products products={bookings} type={"homepage"} />
            </div>
            <div className="toys-adored-customer">Premium Products</div>
            <div className="shop-main__items">
              <Products type={"homepage"} products={premiumProducts} />
            </div>
          </div>

          {/*<Info />*/}
          {/*<LatestNsews />*/}
          {/*<Subscribe />*/}
        </Layout>
      )}
    </>
  );
}
